import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, mainnet, WagmiConfig } from 'wagmi'
import { bsc } from 'wagmi/chains'

const chains = [bsc]
const projectId = '1ee662092220d9e279a243cd7270bbae'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
  autoConnect: false,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

const variables = {
  primaryColor: "#000000",
  secondaryColor: "#ffffff",
  backgroundColor: "#ffffff",
  borderRadius: "10px",
  textColor: "#333333",
  shadowColor: "rgba(0, 0, 0, 0.3)",
  transitionDuration: "0.2s",
};

  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <WagmiConfig config={wagmiConfig}>
        <App />
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} 

themeMode="dark"
themeVariables={{
      '--w3m-font-family': 'Roboto, sans-serif',
      '--w3m-accent-color': '#',
      '--w3m-accent-fill-color': 'white',
      '--w3m-background-color': '#',
}}


      
      ></Web3Modal>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
